// Generated by Framer (1d71865)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./NvxbY2Gkr-0.js";
import * as localizedValues1 from "./NvxbY2Gkr-1.js";

const enabledGestures = {dvuW6wTUJ: {hover: true}};

const cycleOrder = ["dvuW6wTUJ"];

const serializationHash = "framer-jqt0g"

const variantClassNames = {dvuW6wTUJ: "framer-v-3gipey"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {qFQHIOpJU: localizedValues1, VMMHDo1EA: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link, tap, title, width, ...props}) => { return {...props, kNNQYZ_EQ: tap ?? props.kNNQYZ_EQ, uKYuP0iNu: link ?? props.uKYuP0iNu, ZsJx47HSy: title ?? props.ZsJx47HSy ?? "button"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;link?: string;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ZsJx47HSy, uKYuP0iNu, kNNQYZ_EQ, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "dvuW6wTUJ", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1ei0ka = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (kNNQYZ_EQ) {const res = await kNNQYZ_EQ(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={uKYuP0iNu} openInNewTab={false} smoothScroll><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-3gipey", className, classNames)} framer-1g7ve9f`} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"dvuW6wTUJ"} onTap={onTap1ei0ka} ref={ref ?? ref1} style={{backgroundColor: "var(--token-914f1cd3-e142-421c-8856-f3027e374828, rgb(68, 58, 216))", borderBottomLeftRadius: 5, borderBottomRightRadius: 5, borderTopLeftRadius: 5, borderTopRightRadius: 5, ...style}} variants={{"dvuW6wTUJ-hover": {backgroundColor: "var(--token-7924e0d1-5d6e-4585-bc88-3bf69be179fa, rgb(129, 121, 251))"}}} {...addPropertyOverrides({"dvuW6wTUJ-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItTWVkaXVt", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "14px", "--framer-font-weight": "500", "--framer-letter-spacing": "0.07px", "--framer-line-height": "20px", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-e321b03f-d732-43f9-bbf7-842b7ca11fae, rgb(255, 255, 255)))"}}>button</motion.p></React.Fragment>} className={"framer-2k114j"} data-framer-name={"Label"} fonts={["Inter-Medium"]} layoutDependency={layoutDependency} layoutId={"B2yeZzW1Z"} style={{"--extracted-r6o4lv": "var(--token-e321b03f-d732-43f9-bbf7-842b7ca11fae, rgb(255, 255, 255))"}} text={ZsJx47HSy} verticalAlignment={"center"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-jqt0g.framer-1g7ve9f, .framer-jqt0g .framer-1g7ve9f { display: block; }", ".framer-jqt0g.framer-3gipey { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 6px; height: min-content; justify-content: center; overflow: visible; padding: 7px 18px 7px 18px; position: relative; text-decoration: none; width: 150px; }", ".framer-jqt0g .framer-2k114j { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-jqt0g.framer-3gipey { gap: 0px; } .framer-jqt0g.framer-3gipey > * { margin: 0px; margin-left: calc(6px / 2); margin-right: calc(6px / 2); } .framer-jqt0g.framer-3gipey > :first-child { margin-left: 0px; } .framer-jqt0g.framer-3gipey > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 34
 * @framerIntrinsicWidth 150
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"sbfyemD5a":{"layout":["fixed","auto"]}}}
 * @framerVariables {"ZsJx47HSy":"title","uKYuP0iNu":"link","kNNQYZ_EQ":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerNvxbY2Gkr: React.ComponentType<Props> = withCSS(Component, css, "framer-jqt0g") as typeof Component;
export default FramerNvxbY2Gkr;

FramerNvxbY2Gkr.displayName = "button-primary";

FramerNvxbY2Gkr.defaultProps = {height: 34, width: 150};

addPropertyControls(FramerNvxbY2Gkr, {ZsJx47HSy: {defaultValue: "button", displayTextArea: false, title: "Title", type: ControlType.String}, uKYuP0iNu: {title: "Link", type: ControlType.Link}, kNNQYZ_EQ: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerNvxbY2Gkr, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5A3Ce6C9YYmCjpQx9M4inSaKU.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/Qx95Xyt0Ka3SGhinnbXIGpEIyP4.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/6mJuEAguuIuMog10gGvH5d3cl8.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/xYYWaj7wCU5zSQH0eXvSaS19wo.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/otTaNuNpVK4RbdlT7zDDdKvQBA.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/d3tHnaQIAeqiE5hGcRw4mmgWYU.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/DolVirEGb34pEXEp8t8FQBSK4.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})